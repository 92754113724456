<template>
  <div :class="`mt-1 text-${size} text-red-500 flex flex-row gap-2`">
    <font-awesome-icon :icon="['far', 'exclamation-circle']" class="my-auto" />
    <span v-if="error">{{ error }}</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'p-error',
  props: {
    error: {
      type:String,
      default: ''
    },
    size: {
      type:String,
      default: 'xs',
      validator: (val) => ['xs', 'sm', 'md', 'lg'].indexOf(val) >= 0
    }
  }
};
</script>
