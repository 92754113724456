<template>
  <div class="p-4 text-sm border-b border-gray-300 border-dashed group">
    <div class="relative block pl-6">
      <div class="absolute top-0 left-0">
        <FontAwesomeIcon :icon="['far', icon]" />
      </div>

      <div class="font-semibold text-green-500">{{ alert.title }}</div>
      <p class="mt-1">{{ alert.text }}</p>
    </div>
    <PButton
      class="text-green-600 underline"
      color="white"
      :disable="readDate"
      @click="markAsRead"
    >{{ readDate ? `${$tk('Common.General.Read')} ${readDate}` : $tk('PAlertsDropdown.MarkAsRead') }}</PButton>
  </div>
</template>

<script>
import http from "@/utilities/http"
import { getAlertIcon } from '@/utilities/alertHelpers';

export default {
  props: {
    alert: { type: Object, required: true },
  },
  computed: {
    icon() {
      return getAlertIcon(this.alert.source)
    },
    readDate() {
      return alert.data?.read
    }
  },
  methods: {
    async markAsRead() {
      await http.post("MessageRead", {
        id: this.alert.sourceId,
        read: true
      })
      await this.$store.dispatch("getAlerts")
    }
  }
}
</script>