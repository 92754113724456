
export function getAlertIcon(source) {
	switch (source) {
		case "Support": return "life-ring"
		case "Message": return "newspaper"
		case "Order": return "pallet"
		case "Invoice": return "file-invoice"
		default: return "exclamation-circle"
	}
}

export function getAlertRouteName(source) {
	switch (source) {
		case "Support": return "support.Tickets"
		case "Message": return "messages.Message"
		case "Order": return "orders"
		case "Invoice": return "reports.Invoices"
		case "Log": return "global.Log"
		case "System": return "messages.System"
		default: return "home"
	}
}