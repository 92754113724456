import { getConfig } from "@/config";

const websiteUrl = () => {
	const config = getConfig();
	const end = config?.hosts[0].split(".").pop();
	console.debug("web:", end);
	return `https://www.smartretur.${end === "localhost" ? "no" : end}`;
};

export { websiteUrl };
