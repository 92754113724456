<template>
  <div
    :class="themeClassName"
    :style="`background-image:url('${backgroundImage}')`"
    class="h-screen external-layout"
  >
    <div class="overlay"></div>
    <div
      class="max-h-screen p-10 m-auto overflow-y-auto rounded-lg shadow-xl box bg-gradient-to-b from-gray-100 to-gray-200 md:overflow-y-auto"
    >
      <div class="mb-10">
        <img :src="logoUrl" alt="Logo" class="external-logo" />
        <div class="mt-1 text-xs leading-none text-gray-700">
          APP: {{ currentPackageVersion }} | {{ info }}
        </div>
      </div>
      <div v-if="!config">
        Dette er en gammel side som ikke lenger er i bruk. Gå til
        <a :href="newPortalUrl" class="text-green-600">ny portal</a>.
      </div>
      <div v-else-if="loading" class="text-2xl text-center">
        <FontAwesomeIcon :icon="['far', 'spinner']" spin></FontAwesomeIcon>
      </div>
      <div
        v-else-if="!status && !loading && triedToFetchStatus"
        class="grid"
      >
        <p class="mx-auto text-2xl">Tjenesten er for øyeblikket utilgjengelig.</p>
        <p>Besøk <a
            :href="websiteUrl"
            class="text-green-600 underline"
          >våre nettsider</a> eller ta kontakt med din kontaktperson for mer
          informasjon.
        </p>
        <FontAwesomeIcon
          :icon="['far', 'construction']"
          class="mx-auto mt-8 text-5xl"
        />
      </div>
      <router-view v-else></router-view>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';

import { getConfig, getTheme } from '@/config';
import http from '@/utilities/http';
import { websiteUrl } from '@/utilities/websiteUrl';

import LocaleService from '@/services/LocaleService';
import TokenService from '@/services/TokenService';

const config = getConfig();
const theme = getTheme();

export default {
  data() {
    return {
      config,
      loading: false,
    }
  },
  computed: {
    logoUrl() {
      return theme.logoBlack;
    },
    backgroundImage() {
      return theme.backgroundImage;
    },
    themeClassName() {
      return theme.themeClassName;
    },
    currentPackageVersion() {
      return process.env.PACKAGE_VERSION;
    },
    info() {
      return get(this.status, 'info', '');
    },
    websiteUrl() {
      return websiteUrl();
    },
    newPortalUrl() {
      return location.hostname.indexOf('365') < 0
        ? 'https://portal.smartretur.no'
        : `https://${location.hostname.replace('365', '')}`;
    }
  },
  async created() {
    this.loading = true;
    setTimeout(() => {
      this.triedToFetchStatus = true;
      this.loading = false;
    }, 10000);

    if (this.$route.query.auth) TokenService.setToken(this.$route.query.auth);
    this.$i18n.locale = LocaleService.getLocale();

    this.status = await http.get("Status")

    this.triedToFetchStatus = true
    this.loading = false
  },
  mounted() {
    const locale = LocaleService.getLocale();
    document.documentElement.setAttribute('lang', locale.substr(0, 2));
  }
};
</script>

<style scoped>
.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.box {
  position: absolute;
  top: 20px;
  left: 5%;
  right: 5%;
  z-index: 10;
}

@screen md {
  .box {
    left: 50%;
    right: auto;
    top: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    max-width: 98vw;
  }
}
</style>
