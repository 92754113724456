<template>
  <div>
    <PLabel
      :disabled="disabled"
      :error="error"
      :label="label"
      :required="required"
      @click="$refs.input.focus()"
    />

    <div class="relative flex items-center">
      <FontAwesomeIcon
        v-if="iconInsideLeft"
        :icon="['far', iconInsideLeft]"
        class="absolute left-0 ml-3 text-gray-400"
      />

      <the-mask
        v-if="mask"
        ref="input"
        :class="classNames"
        :disabled="disabled"
        :mask="mask"
        :masked="masked"
        :placeholder="placeholder || mask"
        :readonly="readonly"
        :required="required"
        :type="type"
        :value="value"
        @input="onInput"
      />

      <input
        v-else
        ref="input"
        :class="classNames"
        :disabled="disabled"
        :placeholder="placeholder"
        :readonly="readonly"
        :required="required"
        :type="type"
        :value="value"
        @input="onInput"
      />

      <button
        v-if="clearable && value"
        class="absolute right-0 p-2 mr-1 text-sm text-gray-600 rounded focus:outline-none focus:text-gray-900 hover:text-gray-900 focus:bg-red-100"
        @click="$emit('input', '')"
      >
        <FontAwesomeIcon :icon="['far', 'times']" />
      </button>
    </div>

    <PError v-if="error" :error="error" />
  </div>
</template>

<script>
import PLabel from "./partials/PLabel";
import PError from "./partials/PError";
import debounce from "lodash/debounce";

const inputCaptured = function (ev) {
  // the-mask returns the srcElement value
  this.$emit("input", this.mask ? ev : ev.srcElement.value);
};

export default {
  name: "p-input",
  components: {
    PLabel,
    PError,
  },
  props: {
    value: { type: String, default: "" },
    label: { type: String, default: "" },
    type: {
      type: String,
      default: "text",
      validator: (val) =>
        ["text", "email", "url", "password"].indexOf(val) >= 0,
    },
    placeholder: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    error: { type: String, default: "" },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    iconInsideLeft: { type: String, default: "" },
    wait: { type: Number, default: 0 },
    mask: { type: [String, Array], required: false },
    masked: { type: Boolean, default: false },
    focus: { type: Boolean, default: false },
  },

  computed: {
    classNames() {
      return {
        "p-2 shadow-inner h-10 border rounded leading-none w-full focus:outline-none": true,
        "bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed":
          this.disabled,
        "border-gray-400 focus:border-white focus:shadow-outline placeholder-gray-400":
          !this.error && !this.disabled,
        "border-red-500 focus:border-red-600 placeholder-red-300 text-red-600":
          this.error,
        "pl-8": this.iconInsideLeft,
      };
    },
    onInput() {
      return debounce(inputCaptured, this.wait).bind(this);
    },
  },

  // mounted() {
  //   if (this.focus) {
  //     this.$refs.input?.focus();
  //   }
  // },
  watch: {
    focus: {
      handler: function (val) {
        if (val) {
          this.$refs.input?.focus();
        }
      },
      immediate: true,
    },
  },
};
</script>
