var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PLabel',{attrs:{"label":_vm.label,"error":_vm.error},on:{"click":function($event){return _vm.$refs.input.focus()}}}),_c('div',{staticClass:"relative group"},[(_vm.warnActive)?_c('FontAwesomeIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.warnMessage, delay: { show: 100, hide: 100 } }),expression:"{ content: warnMessage, delay: { show: 100, hide: 100 } }"}],staticClass:"absolute ml-2 text-sm text-yellow-500 top-1/2",attrs:{"icon":['far', 'exclamation-triangle']}}):_vm._e(),_c('input',{ref:"input",staticClass:"w-full leading-none border rounded shadow-inner focus:outline-none",class:_vm.classNames,attrs:{"data-extra":_vm.extra,"data-max":_vm.max,"data-min":_vm.min,"data-step":_vm.step,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"readonly":_vm.readonly,"tabindex":_vm.tabIndex},domProps:{"value":_vm.value},on:{"focus":(event) => event.target.select(),"input":_vm.onInput,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;return _vm.decrease.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;return _vm.increase.apply(null, arguments)},_vm.onKeyDown]}}),(!_vm.readonly)?_c('button',{staticClass:"absolute top-0 right-0 flex items-center justify-center w-6 border-b border-l h-1/2 focus:outline-none",class:{
        'cursor-not-allowed text-gray-400 border-gray-300':
          _vm.disabled || _vm.max === 0 || _vm.value === _vm.max,
        'text-gray-500 border-gray-400 hover:text-gray-900 group-focus:border-gray-500':
          !_vm.disabled && _vm.max !== 0 && _vm.value < _vm.max
      },attrs:{"disabled":_vm.disabled || _vm.value >= _vm.max,"tabindex":"-1"},on:{"click":function($event){$event.preventDefault();return _vm.increase.apply(null, arguments)}}},[_c('FontAwesomeIcon',{staticClass:"text-xs",attrs:{"icon":['far', 'chevron-up']}})],1):_vm._e(),(!_vm.readonly)?_c('button',{staticClass:"absolute bottom-0 right-0 flex items-center justify-center w-6 border-l h-1/2 focus:outline-none",class:{
        'cursor-not-allowed text-gray-400 border-gray-300':
          _vm.disabled || _vm.max === 0 || _vm.value === _vm.min,
        'text-gray-500 border-gray-400 hover:text-gray-900 group-focus:border-gray-500':
          !_vm.disabled && _vm.max !== 0 && _vm.value > _vm.min
      },attrs:{"disabled":_vm.disabled || _vm.value === _vm.min,"tabindex":"-1"},on:{"click":function($event){$event.preventDefault();return _vm.decrease.apply(null, arguments)}}},[_c('FontAwesomeIcon',{staticClass:"text-xs",attrs:{"icon":['far', 'chevron-down']}})],1):_vm._e()],1),(_vm.error)?_c('PError',{attrs:{"error":_vm.error}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }