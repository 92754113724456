<template>
    <PDialog :title="$t('PUserSelectDialog.SelectUser')" @close="onClose" classes="w-11/12 md:w-2/3 lg:w-2/5">

      <form class="flex flex-col flex-1" @submit.prevent="onSubmit">

        <PDialogContent>

          <PSelect
            v-model="selectedCustomerId"
            :disabled="!canChangeCustomer || isLoading"
            :focus="true"
            :height="400"
            :items="customers"
            :label="$tk('PUserSelectDialog.SelectCustomer')"
            :loading="isLoading"
            :searchable="true"
            :startOpen="canChangeCustomer"
            itemText="name"
            itemValue="id"
          >
            <template v-slot:caption="{ item }">
              <PCustomerSelectItem :item="item" />
            </template>
            <template v-slot="{ item }">
              <PCustomerSelectItem :item="item" />
            </template>
          </PSelect>

          <PSelect
            v-model="selectedLocationId"
            :disabled="!canChangeLocation || isLoading"
            :items="locations"
            :label="$tk('PUserSelectDialog.SelectLocation')"
            :searchable="true"
            class="mt-4"
            itemText="name"
            itemValue="id"
          >
            <template v-slot:caption="{ item }">
              <PLocationSelectItem :item="item" />
            </template>
            <template v-slot="{ item }">
              <PLocationSelectItem :item="item" />
            </template>
          </PSelect>

          <PSelect
            v-if="selectedUserId"
            v-model="selectedUserId"
            :disabled="isLoading || !canChangeUser"
            :items="availableUsers"
            :label="$tk('PUserSelectDialog.SelectUser')"
            :searchable="true"
            class="mt-4"
          />

        </PDialogContent>

        <PDialogActions>

           <div class="flex w-full space-x-2" :class="{'justify-between': canReset }">

            <PButton
              v-if="canReset"
              :disabled="isLoading"
              :loading="isResetting"
              color="secondary"
              @click="onReset"
            >
              <span v-html="$tk('PUserSelectDialog.BackToLoggedInUser')"></span>
            </PButton>

            <div class="flex justify-end flex-1 space-x-2">

              <PButton
                :disabled="isLoading || isSubmitting"
                color="secondary"
                @click="onClose"
              >
                <span v-html="$tk('Common.Actions.Cancel')"></span>
              </PButton>

              <PButton
                :disabled="isLoading"
                :loading="isSubmitting"
                class="w-24"
                color="primary"
                type="submit"
              >
                <span v-html="$tk('Common.Actions.Select')"></span>
              </PButton>

            </div>
          </div>

        </PDialogActions>

      </form>

    </PDialog>

</template>

<script>
import http from "@/utilities/http"
import { mapGetters } from "vuex"
import PCustomerSelectItem from "./components/PCustomerSelectItem.vue"
import PLocationSelectItem from "./components/PLocationSelectItem.vue"
import {
  map,
  get,
  filter,
  find,
  orderBy
} from "lodash"

export default {

  name: "p-user-select-dialog",

  components: {
    PCustomerSelectItem,
    PLocationSelectItem
  },

  data () {
    return {
      customers: [],
      locations: [],
      users: [],
      selectedCustomerId: null,
      selectedLocationId: null,
      selectedUserId: null,
      isLoading: false,
      isSubmitting: false,
      isResetting: false
    }
  },

  computed: {

    ...mapGetters([
      "customer",
      "location",
      "user",
      "loginUser"
    ]),

    canChangeCustomer () {
      return get(this.loginUser, 'isGlobalAdmin', false)
    },

    canChangeLocation () {
      return this.locations.length > 1
      //return get(this.loginUser, 'isCustomerAdmin', false)
    },

    canChangeUser () {
      return get(this.loginUser, 'isGlobalAdmin', false) && get(this.loginUser, 'isSmartRetur', false)
    },

    canReset () {
      return this.loginUser.id !== this.user.id
    },

    availableUsers () {
      return filter(this.users, u => u.isCustomerAdmin || u.locationId === this.selectedLocationId)
    },

  },

  // ---

  methods: {

    // -- handlers

    onClose () {
      this.$emit("close")
    },

    async onReset () {

      this.isResetting = true

      await this.$store.dispatch("updateStatus", {
        customerId: this.loginUser.customerId,
        locationId: this.loginUser.locationId,
        userId: this.loginUser.id
      })

      await this.$store.dispatch("getWebtaskUsers")

      location.href = "/app/home"
    },

    async onSubmit () {

      this.isSubmitting = true

      await this.$store.dispatch("updateStatus", {
        customerId: this.selectedCustomerId,
        locationId: this.selectedLocationId,
        userId: this.selectedUserId
      })

      location.href = "/app/home"

    },

    // ---

    async getCustomers () {

      if (this.canChangeCustomer) {

        let customers

        try {
          customers = await http.get("Customers", {
            params: {
              include: "id,name,status,statusId"
            }
          })
        } catch {
          customers = []
        }

        return filter(customers, c => c.statusId === 1 || c.statusId === 2)

      } else {

        return [{
          id: this.customer.id,
          name: this.customer.name,
          status: this.customer.status,
          statusId: this.customer.statusId
        }]

      }

    },

    // ---

    async getLocations () {

      let locations

      try {
        locations = await http.get("Locations", { params: {
          customerId: this.selectedCustomerId,
          canSwitch: true,
          include: "id,name,isDefault,statusId,typeId"
        }})
      } catch {
        locations = []
      }

      locations = filter(locations, l =>
        (
          l.statusId === 1 ||
          l.statusId === 2
        ) &&
        (
          l.typeId === "LEV" ||
          l.typeId === "NLP" ||
          l.typeId === "GRS" ||
          l.typeId === "TRP" ||
          l.typeId === "BUT"
        )
      )

      return locations
      // return map(locations, l => {
      //   return {
      //     value: l.id,
      //     text: `${l.name} [${l.id}]`,
      //     isDefault: l.isDefault
      //   }
      // })

    },

    // ---

    async getUsers () {

      let users

      try {
        users = await http.get("Users", { params: {
          customerId: this.selectedCustomerId,
          isActive: true,
          include: "id,name,locationId,loginName,isCustomerAdmin"
        }})
      } catch {
        users = []
      }

      return orderBy(
        map(users, u => {
          return {
            value: u.id,
            text: `${u.name} [${u.loginName}]`,
            locationId: u.locationId,
            isCustomerAdmin: u.isCustomerAdmin
          }
        })
      , ["text"], ["asc"])

    }

  },

  // ---

  async created () {
    this.isLoading = true
    const customers = await this.getCustomers()
    this.customers = Object.freeze(customers)
    this.selectedCustomerId = this.customer.id
    this.isLoading = false
  },

  // ---

  watch: {

    // ---

    selectedCustomerId: {

      handler: async function (val) {

        if (val) {

          this.isLoading = true

          const [
            locations,
            users
          ] = await Promise.all([
            this.getLocations(),
            this.getUsers()
          ])

          this.locations = Object.freeze(locations)
          this.users = Object.freeze(users)

          if (locations.length > 0) {

            const currentLocation = find(locations, l => l.id === this.location.id)

            if (currentLocation) {
              this.selectedLocationId = currentLocation.id
            }
            else {
              const defaultLocation = find(locations, l => l.isDefault)
              this.selectedLocationId = defaultLocation
                ? defaultLocation.id
                : locations[0].id
            }

          }

          this.isLoading = false

        }
      },
      immediate: true
    },

    // ---

    selectedLocationId: {

      handler: function () {

        if (this.availableUsers.length > 0) {

          const currentUser = find(this.availableUsers, user => user.value === this.user.id)
          const adminUser = find(this.availableUsers, user => user.isCustomerAdmin)

          if (currentUser) {
            this.selectedUserId = currentUser.value
          }
          else if (adminUser.value) {
            this.selectedUserId = adminUser.value
          }
          else {
            this.selectedUserId = this.availableUsers[0].value
          }

        }

      }, immediate: true

    }

    // ---

  }

}
</script>
