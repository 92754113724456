export const getConfig = () => {
	const configs = [
		// ****** LOCAL ******
		{
			hosts: ["localhost"],
			// !!  Locahost testing apis -- TEST
			apiUrl: "https://api-dev.smartretur.no/api/1/nor/",
			// apiUrl: "https://api-dev.smartretur.no/api/1/sve/",
			// apiUrl: "https://api-dev.smartretur.no/api/1/dan/",
			// apiUrl: "https://api-dev.smartretur.no/api/1/mgw/",
			// apiUrl: "https://api-dev.smartretur.no/api/1/ppa/",
			// !!  Locahost testing apis -- QA
			// apiUrl: "https://api-qa.smartretur.no/api/1/nor/",
			// apiUrl: "https://api-qa.smartretur.no/api/1/sve/",
			// apiUrl: "https://api-qa.smartretur.no/api/1/dan/",
			// apiUrl: "https://api-qa.smartretur.no/api/1/mgw/",
			// apiUrl: "https://api-qa.smartretur.no/api/1/ppa/",
			// !!  Locahost testing apis -- AX TEST
			// apiUrl: "https://apiax-dev.smartretur.no/api/1/nor/",
			// apiUrl: "https://apiax-dev.smartretur.no/api/1/sve/",
			// apiUrl: "https://apiax-dev.smartretur.no/api/1/dan/",
			// apiUrl: "https://apiax-dev.smartretur.no/api/1/mgw/",
			// !!  Locahost testing apis -- AX QA
			// apiUrl: "https://apiax-qa.smartretur.no/api/1/nor/",
			// apiUrl: "https://apiax-qa.smartretur.no/api/1/sve/",
			// apiUrl: "https://apiax-qa.smartretur.no/api/1/dan/",
			// apiUrl: "https://apiax-qa.smartretur.no/api/1/mgw/",
			assetsUrl: "https://api-dev.smartretur.no",
			locales: "main",
			theme: "default",
			agreementUserIdRequired: true,
		},
		// !! *** TEST / DEV ****** NO
		{
			hosts: [
				"portal-test.smartretur.no",
				"digipall-test.smartretur.no",
				"digipall2-test.smartretur.no",
			],
			apiUrl: "https://api-dev.smartretur.no/api/1/nor/",
			assetsUrl: "https://api-dev.smartretur.no",
			locales: "main",
			theme: "default",
			agreementUserIdRequired: true,
		},
		// !! *** TEST / DEV ****** SE
		{
			hosts: [
				"portal-test.smartretur.se",
				"digipall-test.smartretur.se",
				"digipall2-test.smartretur.se",
			],
			apiUrl: "https://api-dev.smartretur.no/api/1/sve/",
			assetsUrl: "https://api-dev.smartretur.no",
			locales: "main",
			theme: "default",
		},
		// !! *** TEST / DEV ****** DK
		{
			hosts: [
				"portal-test.smartretur.dk",
				"digipall-test.smartretur.dk",
				"digipall2-test.smartretur.dk",
			],
			apiUrl: "https://api-dev.smartretur.no/api/1/dan/",
			assetsUrl: "https://api-dev.smartretur.no",
			locales: "main",
			theme: "default",
		},
		// !! *** TEST / DEV ****** MGW
		{
			hosts: ["portal-test.smartwash.dk"],
			apiUrl: "https://api-dev.smartretur.no/api/1/mgw/",
			assetsUrl: "https://api-dev.smartretur.no",
			locales: "main",
			theme: "swa",
		},
		// !! *** TEST / DEV ****** PPA
		{
			hosts: [
				"portal-test.provipal.dk",
				"ppaportal-test.smartretur.no",
				"ppaportal-test.smartretur.dk",
			],
			apiUrl: "https://api-dev.smartretur.no/api/1/ppa/",
			assetsUrl: "https://api-dev.smartretur.no",
			locales: "main",
			theme: "ppa",
		},
		// !! *** QA ****** NO
		{
			hosts: ["portal-qa.smartretur.no"],
			apiUrl: "https://api-qa.smartretur.no/api/1/nor/",
			assetsUrl: "https://api-qa.smartretur.no",
			locales: "main",
			theme: "default",
			agreementUserIdRequired: true,
		},
		// !! *** QA ****** SE
		{
			hosts: ["portal-qa.smartretur.se"],
			apiUrl: "https://api-qa.smartretur.no/api/1/sve/",
			assetsUrl: "https://api-qa.smartretur.no",
			locales: "main",
			theme: "default",
		},
		// !! *** QA ****** DK
		{
			hosts: ["portal-qa.smartretur.dk"],
			apiUrl: "https://api-qa.smartretur.no/api/1/dan/",
			assetsUrl: "https://api-qa.smartretur.no",
			locales: "main",
			theme: "default",
		},
		// !! *** QA ****** MGW
		{
			hosts: ["portal-qa.smartwash.dk"],
			apiUrl: "https://api-qa.smartretur.no/api/1/mgw/",
			assetsUrl: "https://api-qa.smartretur.no",
			locales: "main",
			theme: "swa",
		},
		// !! *** QA ****** PPA
		{
			hosts: ["portal-qa.provipal.dk"],
			apiUrl: "https://api-qa.smartretur.no/api/1/ppa/",
			assetsUrl: "https://api-qa.smartretur.no",
			locales: "main",
			theme: "ppa",
		},
		// !! *** PROD ****** NO
		{
			hosts: [
				"portal.smartretur.no",
				"digipall.smartretur.no",
				"digipall2.smartretur.no",
			],
			apiUrl: "https://api.smartretur.no/api/1/nor/",
			assetsUrl: "https://api.smartretur.no",
			locales: "main",
			theme: "default",
			agreementUserIdRequired: true,
		},
		// !! *** PROD ****** SE
		{
			hosts: [
				"portal.smartretur.se",
				"digipall.smartretur.se",
				"digipall2.smartretur.se",
			],
			apiUrl: "https://api.smartretur.no/api/1/sve/",
			assetsUrl: "https://api.smartretur.no",
			locales: "main",
			theme: "default",
		},
		// !! *** PROD ****** DK
		{
			hosts: [
				"portal.smartretur.dk",
				"digipall.smartretur.dk",
				"digipall2.smartretur.dk",
			],
			apiUrl: "https://api.smartretur.no/api/1/dan/",
			assetsUrl: "https://api.smartretur.no",
			locales: "main",
			theme: "default",
			agreementUserIdRequired: true,
		},
		// !! *** PROD ****** MGW
		{
			hosts: ["portal.smartwash.dk"],
			apiUrl: "https://api.smartretur.no/api/1/mgw/",
			assetsUrl: "https://api.smartretur.no",
			locales: "main",
			theme: "swa",
		},
		// !! *** PROD ****** PPA
		{
			hosts: ["portal.provipal.dk", "ppaportal.smartretur.dk"],
			apiUrl: "https://api.smartretur.no/api/1/ppa/",
			assetsUrl: "https://api.smartretur.no",
			locales: "main",
			theme: "ppa",
		},

		// !! *** AX *** --- LEGACY SYSTEM: to be removed
		// *** --- AX -- QA --- NO
		{
			hosts: ["portalax-qa.smartretur.no"],
			apiUrl: "https://apiax-qa.smartretur.no/api/1/nor/",
			assetsUrl: "https://apiax-qa.smartretur.no",
			locales: "main",
			theme: "default",
			agreementUserIdRequired: true,
		},
		// *** --- AX -- QA --- SE
		{
			hosts: ["portalax-qa.smartretur.se"],
			apiUrl: "https://apiax-qa.smartretur.no/api/1/sve/",
			assetsUrl: "https://apiax-qa.smartretur.no",
			locales: "main",
			theme: "default",
			agreementUserIdRequired: true,
		},
		// *** --- AX -- QA --- DK
		{
			hosts: ["portalax-qa.smartretur.dk"],
			apiUrl: "https://apiax-qa.smartretur.no/api/1/dan/",
			assetsUrl: "https://apiax-qa.smartretur.no",
			locales: "main",
			theme: "default",
			agreementUserIdRequired: true,
		},
		// *** --- AX -- QA --- MGW
		{
			hosts: ["portalax-qa.smartwash.dk"],
			apiUrl: "https://apiax-qa.smartretur.no/api/1/mgw/",
			assetsUrl: "https://apiax-qa.smartretur.no",
			locales: "main",
			theme: "default",
			agreementUserIdRequired: true,
		},
		// *** --- AX -- QA --- PPA
		{
			hosts: ["portalax-qa.provipal.dk"],
			apiUrl: "https://apiax-qa.smartretur.no/api/1/ppa/",
			assetsUrl: "https://apiax-qa.smartretur.no",
			locales: "main",
			theme: "default",
			agreementUserIdRequired: true,
		},
		// *** --- AX -- TEST / DEV --- NO
		{
			hosts: ["portalax-test.smartretur.no"],
			apiUrl: "https://apiax-dev.smartretur.no/api/1/nor/",
			assetsUrl: "https://apiax-dev.smartretur.no",
			locales: "main",
			theme: "default",
			agreementUserIdRequired: true,
		},
		// *** --- AX -- TEST / DEV --- SE
		{
			hosts: ["portalax-test.smartretur.se"],
			apiUrl: "https://apiax-dev.smartretur.no/api/1/sve/",
			assetsUrl: "https://apiax-dev.smartretur.no",
			locales: "main",
			theme: "default",
			agreementUserIdRequired: true,
		},
		// *** --- AX -- TEST / DEV --- DK
		{
			hosts: ["portalax-test.smartretur.dk"],
			apiUrl: "https://apiax-dev.smartretur.no/api/1/dan/",
			assetsUrl: "https://apiax-dev.smartretur.no",
			locales: "main",
			theme: "default",
			agreementUserIdRequired: true,
		},
		// *** --- AX -- TEST / DEV --- MGW
		{
			hosts: ["portalax-test.smartwash.dk"],
			apiUrl: "https://apiax-dev.smartretur.no/api/1/mgw/",
			assetsUrl: "https://apiax-dev.smartretur.no",
			locales: "main",
			theme: "default",
			agreementUserIdRequired: true,
		},
		// *** --- AX -- TEST / DEV --- PPA
		{
			hosts: ["portalax-test.provipal.dk"],
			apiUrl: "https://apiax-dev.smartretur.no/api/1/ppa/",
			assetsUrl: "https://apiax-dev.smartretur.no",
			locales: "main",
			theme: "default",
			agreementUserIdRequired: true,
		},
	];

	const host = location.hostname.toLowerCase();

	return configs.find((config) => config.hosts?.includes(host));
};

export const getTheme = () => {
	const themes = [
		{
			name: "default",
			title: "SmartRetur Portal",
			logoWhite: "/img/themes/default/logo-white.svg",
			logoBlack: "/img/themes/default/logo-black.svg",
			backgroundImage: "/img/themes/default/bg.jpg",
			themeClassName: "theme-default",
		},
		{
			name: "cma",
			title: "Portal",
			logoWhite: "/img/themes/cma/logo-white.png",
			logoBlack: "/img/themes/cma/logo-black.png",
			backgroundImage: "/img/themes/cma/bg.jpg",
			themeClassName: "theme-cma",
		},
		{
			name: "ppa",
			title: "Portal",
			logoWhite: "/img/themes/ppa/logo-white.svg",
			logoBlack: "/img/themes/ppa/logo-black.png",
			backgroundImage: "/img/themes/ppa/bg.jpg",
			themeClassName: "theme-ppa",
		},
		{
			name: "swa",
			title: "SmartWash Portal",
			logoWhite: "/img/themes/swa/logo-white.svg",
			logoBlack: "/img/themes/swa/logo-black.svg",
			backgroundImage: "/img/themes/swa/bg.jpg",
			themeClassName: "theme-swa",
		},
	];

	const config = getConfig();

	return themes.find((theme) => theme.name === config?.theme) || themes[0];
};
