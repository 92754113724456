<template>
  <div>
    <PLabel :label="label" :error="error" @click="$refs.input.focus()" />
    <div class="relative group">
      <FontAwesomeIcon
        v-if="warnActive"
        :icon="['far', 'exclamation-triangle']"
        class="absolute ml-2 text-sm text-yellow-500 top-1/2"
        v-tooltip="{ content: warnMessage, delay: { show: 100, hide: 100 } }"
      />
      <input
        ref="input"
        :class="classNames"
        :data-extra="extra"
        :data-max="max"
        :data-min="min"
        :data-step="step"
        :disabled="disabled"
        :placeholder="placeholder"
        :readonly="readonly"
        :tabindex="tabIndex"
        :value="value"
        class="w-full leading-none border rounded shadow-inner focus:outline-none"
        @focus="(event) => event.target.select()"
        @input="onInput"
        @keydown.down="decrease"
        @keydown.up="increase"
        @keydown="onKeyDown"
      />
      <button
        v-if="!readonly"
        :class="{
          'cursor-not-allowed text-gray-400 border-gray-300':
            disabled || max === 0 || value === max,
          'text-gray-500 border-gray-400 hover:text-gray-900 group-focus:border-gray-500':
            !disabled && max !== 0 && value < max
        }"
        :disabled="disabled || value >= max"
        class="absolute top-0 right-0 flex items-center justify-center w-6 border-b border-l h-1/2 focus:outline-none"
        tabindex="-1"
        @click.prevent="increase"
      >
        <FontAwesomeIcon class="text-xs" :icon="['far', 'chevron-up']" />
      </button>
      <button
        v-if="!readonly"
        :class="{
          'cursor-not-allowed text-gray-400 border-gray-300':
            disabled || max === 0 || value === min,
          'text-gray-500 border-gray-400 hover:text-gray-900 group-focus:border-gray-500':
            !disabled && max !== 0 && value > min
        }"
        :disabled="disabled || value === min"
        class="absolute bottom-0 right-0 flex items-center justify-center w-6 border-l h-1/2 focus:outline-none"
        tabindex="-1"
        @click.prevent="decrease"
      >
        <FontAwesomeIcon class="text-xs" :icon="['far', 'chevron-down']" />
      </button>
    </div>
    <PError v-if="error" :error="error" />
  </div>
</template>

<script>
import PLabel from './partials/PLabel';
import PError from './partials/PError';
import debounce from 'lodash/debounce';

const inputCaptured = function (ev) {
  let input = ev.srcElement.value;
  if (input === '') {
    input = '0';
  }
  let num = Number.parseInt(input);
  const step = Number.parseInt(ev.srcElement.getAttribute('data-step'));
  const extra = Number.parseInt(ev.srcElement.getAttribute('data-extra'));
  const maxAttr = ev.srcElement.getAttribute('data-max');

  if (maxAttr !== 'Infinity') {
    const max = Number.parseInt(maxAttr, 10) - extra;
    if (num > max) {
      num = max;
    }
  }

  if (num !== 0) {
    num = Number.parseInt((num - extra) / step, 10) * step + extra;
  }

  ev.srcElement.value = num;
  this.$emit('input', num);
};

// biome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
// biome-ignore lint/suspicious/noGlobalIsNan: <explanation>
const isNaN = Number.isNaN || window.isNaN;

export default {
  name: 'p-number',
  components: {
    PLabel,
    PError
  },
  props: {
    value: { type: Number, default: 0 },
    extra: { type: Number, default: 0 },
    label: { type: String, default: '' },
    max: { type: Number, default: Number.POSITIVE_INFINITY },
    min: { type: Number, default: 0 },
    warn: { type: Number, default: 0 },
    warnBelow: { type: Number, default: 0 },
    warnMessage: { type: String, default: '' },
    step: { type: Number, default: 1 },
    placeholder: { type: String, default: '' },
    size: {
      type: String,
      default: 'normal',
      validator: (value) => ['small', 'normal'].indexOf(value) !== -1
    },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    error: { type: String, default: '' },
    wait: { type: Number, default: 0 },
    focus: { type: Boolean, default: false }
  },
  computed: {
    tabIndex() {
      return this.disabled || this.readonly ? '-1' : '';
    },

    increasable() {
      const num = this.value;
      return isNaN(num) || num + this.step <= this.max;
    },

    decreasable() {
      const num = this.value;
      return isNaN(num) || num - this.step >= this.min;
    },

    warnActive() {
      return (
        this.value > 0 &&
        ((this.warn > 0 && this.value >= this.warn) ||
          (this.warnBelow > 0 && this.value <= this.warnBelow))
      );
    },

    classNames() {
      return {
        'bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed': this.disabled,
        'border-gray-400 focus:border-white focus:shadow-outline':
          !this.error && !this.disabled && !this.readonly,
        'border-red-500 focus:border-red-600 placeholder-red-300 text-red-600': this.error,
        'p-2 h-10': this.size === 'normal',
        'p-1 h-8 text-xs': this.size === 'small',
        'text-right text-gray-500': this.readonly,
        'text-right pr-8': !this.readonly
      };
    },

    onInput() {
      return debounce(inputCaptured, this.wait).bind(this);
    }
  },
  methods: {
    onKeyDown(event) {
      const code = event.keyCode;
      const isValid =
        (code >= 48 && code <= 57) || // 0-9
        (code >= 96 && code <= 105) || // 0-9 numpad
        (code >= 37 && code <= 40) || // arrows
        code === 9 || // tab
        code === 8 || // backspace
        code === 46 || // delete
        (code === 109 && this.min < 0) || // minus (numpad)
        (code === 189 && this.min < 0); // minus
      if (!isValid) {
        event.preventDefault();
      }
    },

    increase() {
      if (!this.increasable) return;
      if (isNaN(this.value)) {
        this.$emit('input', 0);
      }
      this.$emit('input', Number.parseInt(this.value, 10) + this.step);
    },

    decrease() {
      if (!this.decreasable) return;
      if (this.value > this.min) {
        this.$emit('input', Number.parseInt(this.value, 10) - this.step);
      }
    }
  },

  mounted() {
    if (this.focus) {
      this.$refs.input.focus();
    }
  }
};
</script>
