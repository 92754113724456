<template>
  <div v-if="isLoaded">{{ fillPct }}</div>
</template>


<script>
import http from "@/utilities/http"

export default {

  props: ["item"],

  data () {
    return {
      isLoaded: false,
      fill: 0
    }
  },

  computed: {

    fillPct () {
      return `${new Intl.NumberFormat(this.$i18n.locale.replace('-sw', ''), { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(this.fill * 100)}%`
    }
  },

  async created () {

    try {

      this.fill = await http.get("ScheduleOrderFill", {
        params: {
          id: this.item.id
        }
      })

      this.isLoaded = true

    } catch(error) {
      this.$appInsights.trackException({ exception: error })
    }

  }
}
</script>