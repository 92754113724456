<template>
  <p-click-outside :do="() => (calendarVisible = false)">
    <div class="relative" :title="error">
      <PLabel
        :label="label"
        :error="error"
        :required="required"
        @click="$refs.button.focus()"
      />

      <button
        ref="button"
        @click.prevent="toggle"
        @keydown.esc.stop.prevent="esc"
        @keydown.delete="reset"
        :class="toggleButtonClassNames"
        :disabled="disabled"
        class="flex items-center w-full h-10 p-2 text-sm leading-none border shadow-inner focus:outline-none"
      >
        <font-awesome-icon
          :class="{
            'text-gray-300': disabled,
            'text-gray-400': !disabled && !error,
            'text-red-400': error,
          }"
          :icon="['far', 'calendar']"
        />

        <div class="flex-1 ml-2 text-left whitespace-no-wrap">
          <template v-if="selectedDate">
            {{ selectedDate }}
          </template>
          <template v-else-if="placeholder">
            <div class="text-gray-400" v-html="placeholder"></div>
          </template>
        </div>

        <FontAwesomeIcon
          class="mr-1"
          @click.prevent.stop="reset"
          v-if="selectedDate && clearable"
          :class="{
            'text-gray-300': disabled,
            'text-gray-600': !disabled && !error,
            'text-red-400': error,
          }"
          :icon="['far', 'times']"
        />
      </button>

      <div
        v-show="calendarVisible"
        style="margin-top: -1px"
        class="absolute z-20 flex flex-wrap p-2 bg-white border border-gray-500 rounded-b shadow-md w-80"
        :class="{
          'left-0': !rightAlign,
          'right-0': rightAlign,
        }"
      >
        <div
          class="flex items-center w-full text-center bg-white border-gray-500"
        >
          <button
            @click.prevent="prev"
            :disabled="loading"
            class="w-10 pr-2 text-gray-500 border-gray-500 flex-0 hover:text-gray-700 focus:text-gray-700 focus:outline-none"
          >
            <FontAwesomeIcon :icon="['far', 'chevron-left']" />
          </button>

          <div
            class="flex-1 p-2 font-semibold text-gray-700"
            v-html="calendarYearMonthDisplay"
          ></div>

          <button
            @click.prevent="next"
            :disabled="loading"
            class="w-10 pl-2 text-gray-500 border-gray-500 flex-0 hover:text-gray-700 focus:text-gray-700 focus:outline-none"
          >
            <FontAwesomeIcon :icon="['far', 'chevron-right']" />
          </button>
        </div>

        <template v-if="days.length === 0 && !loading">
          <div
            class="w-full p-2 italic text-center"
            v-html="$tk('PDatePicker.NoDaysAvailable')"
          ></div>
        </template>

        <template v-else-if="!loading">
          <div class="flex">
            <div class="flex flex-col justify-end flex-0">
              <div
                v-for="week in weeks"
                :key="week"
                class="flex items-center justify-center w-8 h-8 text-xs leading-none text-gray-500 bg-gray-100"
              >
                {{ week }}
              </div>
            </div>
            <div>
              <div class="flex items-center w-full">
                <div
                  class="px-2 py-3 text-xs font-semibold text-center text-gray-500 w-1/7"
                  v-html="$tk('Common.Days.Mo')"
                ></div>
                <div
                  class="px-2 py-3 text-xs font-semibold text-center text-gray-500 w-1/7"
                  v-html="$tk('Common.Days.Tu')"
                ></div>
                <div
                  class="px-2 py-3 text-xs font-semibold text-center text-gray-500 w-1/7"
                  v-html="$tk('Common.Days.We')"
                ></div>
                <div
                  class="px-2 py-3 text-xs font-semibold text-center text-gray-500 w-1/7"
                  v-html="$tk('Common.Days.Th')"
                ></div>
                <div
                  class="px-2 py-3 text-xs font-semibold text-center text-gray-500 w-1/7"
                  v-html="$tk('Common.Days.Fr')"
                ></div>
                <div
                  class="px-2 py-3 text-xs font-semibold text-center text-gray-500 w-1/7"
                  v-html="$tk('Common.Days.Sa')"
                ></div>
                <div
                  class="px-2 py-3 text-xs font-semibold text-center text-gray-500 w-1/7"
                  v-html="$tk('Common.Days.Su')"
                ></div>
              </div>

              <button
                v-for="(day, index) in days"
                :key="index"
                :tabindex="canSelect(day) ? '' : '-1'"
                :class="{
                  'text-gray-300 cursor-default': canSelect(day) === false,
                  'hover:bg-green-300 hover:text-white focus:bg-green-300 focus:text-white':
                    canSelect(day),
                  'bg-green-500 text-white': day.date === selectedDate,
                  'bg-green-100 rounded-full':
                    day.date !== selectedDate && day.date === today,
                  'ml-1/7': index === 0 && firstDayOfMonth === 2,
                  'ml-2/7': index === 0 && firstDayOfMonth === 3,
                  'ml-3/7': index === 0 && firstDayOfMonth === 4,
                  'ml-4/7': index === 0 && firstDayOfMonth === 5,
                  'ml-5/7': index === 0 && firstDayOfMonth === 6,
                  'ml-6/7': index === 0 && firstDayOfMonth === 0,
                }"
                @click.prevent="select(day)"
                class="w-8 h-8 text-sm leading-none w-1/7 focus:outline-none"
              >
                {{ day.date | day }}
              </button>
            </div>
          </div>
        </template>
      </div>
    </div>
  </p-click-outside>
</template>

<script>
import PLabel from "./partials/PLabel";
import http from "@/utilities/http";
import { add, sub, getISOWeek } from "date-fns";

export default {
  name: "p-date-picker",
  components: { PLabel },
  props: {
    value: { type: String, default: "" },
    label: { type: String, default: "" },
    min: { type: String, required: false },
    max: { type: String, required: false },
    rightAlign: { type: Boolean, default: false },
    placeholder: { type: String, default: "" },
    clearable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    error: { type: String, default: "" },
    focus: { type: Boolean, default: false },
    allOpen: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
  },
  data() {
    return {
      days: [],
      today: "",
      selectedDate: "",
      calendarYearMonth: "",
      calendarVisible: false,
      loading: false,
    };
  },
  computed: {
    toggleButtonClassNames() {
      return {
        "bg-white border-gray-400 focus:border-white focus:shadow-outline":
          !this.error && !this.disabled,
        "bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed":
          this.disabled,
        "border-red-500 focus:border-red-600 placeholder-red-300 text-red-600":
          this.error,
        rounded: !this.calendarVisible,
        "rounded-t": this.calendarVisible,
        "border-gray-500": this.calendarVisible,
      };
    },
    firstDayOfMonth() {
      if (this.calendarYearMonth) {
        return new Date(`${this.calendarYearMonth}-01`).getDay();
      }
      return 0;
    },
    calendarYearMonthDisplay() {
      if (this.calendarYearMonth.length === "yyyy-MM".length) {
        const MM = this.calendarYearMonth.substr(5, 2);
        const monthName =
          MM === "01"
            ? this.$tk("Common.Months.January")
            : MM === "02"
            ? this.$tk("Common.Months.February")
            : MM === "03"
            ? this.$tk("Common.Months.March")
            : MM === "04"
            ? this.$tk("Common.Months.April")
            : MM === "05"
            ? this.$tk("Common.Months.May")
            : MM === "06"
            ? this.$tk("Common.Months.June")
            : MM === "07"
            ? this.$tk("Common.Months.July")
            : MM === "08"
            ? this.$tk("Common.Months.August")
            : MM === "09"
            ? this.$tk("Common.Months.September")
            : MM === "10"
            ? this.$tk("Common.Months.October")
            : MM === "11"
            ? this.$tk("Common.Months.November")
            : MM === "12"
            ? this.$tk("Common.Months.December")
            : "";
        return `${monthName} ${this.calendarYearMonth.substr(0, 4)}`;
      }
      return "";
    },
    weeks() {
      const firstDayOfMonth = new Date(`${this.calendarYearMonth}-01`);
      const lastDayOfMonth = sub(add(firstDayOfMonth, { months: 1 }), {
        days: 1,
      });

      const firstWeek = getISOWeek(firstDayOfMonth);
      const lastWeek = getISOWeek(lastDayOfMonth);

      let arr = [];

      for (let i = firstWeek; i <= lastWeek; i++) {
        arr.push(i);
      }

      return arr;
    },
  },
  filters: {
    day(date) {
      const parts = date.split("-");
      return parseInt(parts[parts.length - 1]);
    },
  },
  methods: {
    toggle() {
      this.calendarVisible = !this.calendarVisible;
    },
    canSelect(day) {
      return (
        this.allOpen ||
        (!this.allOpen &&
          day.isOpen &&
          (!this.min || (this.min && this.min <= day.date)) &&
          (!this.max || (this.max && this.max >= day.date)))
      );
    },
    reset() {
      this.$emit("input", "");
      this.selectedDate = "";
      this.calendarVisible = false;
    },
    select(date) {
      if (this.canSelect(date)) {
        this.$emit("input", date.date);
        this.$refs.button.focus();
        this.selectedDate = date.date;
        this.calendarVisible = false;
      }
    },
    esc() {
      this.calendarVisible = false;
    },
    prev() {
      if (this.calendarYearMonth) {
        const currentYear = parseInt(this.calendarYearMonth.substr(0, 4), 10);
        const currentMonth = parseInt(this.calendarYearMonth.substr(5, 2), 10);
        const prevMonth = currentMonth === 1 ? 12 : currentMonth - 1;
        const prevYear = prevMonth === 12 ? currentYear - 1 : currentYear;
        this.calendarYearMonth = `${prevYear}-${this.pad(prevMonth)}`;
      }
    },
    next() {
      if (this.calendarYearMonth) {
        const currentYear = parseInt(this.calendarYearMonth.substr(0, 4), 10);
        const currentMonth = parseInt(this.calendarYearMonth.substr(5, 2), 10);
        const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;
        const nextYear = nextMonth === 1 ? currentYear + 1 : currentYear;
        this.calendarYearMonth = `${nextYear}-${this.pad(nextMonth)}`;
      }
    },
    pad(number) {
      return number < 10 ? "0" + number : "" + number;
    },
  },
  mounted() {
    if (this.focus) {
      this.$refs.button.focus();
    }
  },
  watch: {
    value: {
      handler: function (val) {
        if (val) {
          this.selectedDate = val.substr(0, "yyyy-MM-dd".length);
        }
      },
      immediate: true,
    },

    calendarVisible: function (visible) {
      if (visible) {
        const dt = new Date();
        const year = dt.getFullYear();
        const month = dt.getMonth() + 1;
        const day = dt.getDate();

        this.today = `${year}-${this.pad(month)}-${this.pad(day)}`;
        if (this.value) {
          this.calendarYearMonth = this.value.substr(0, "yyyy-MM".length);
        } else {
          this.calendarYearMonth = `${year}-${this.pad(month)}`;
        }
      }
    },
    calendarYearMonth: async function (val) {
      if (val) {
        const fromYear = parseInt(val.substr(0, 4), 10);
        const fromMonth = parseInt(val.substr(5, 2), 10);

        const toYear = fromMonth === 12 ? fromYear + 1 : fromYear;
        const toMonth = `${fromMonth === 12 ? 1 : fromMonth + 1}`;

        this.loading = true;
        this.days = await http.get(
          `Days?from=${fromYear}-${this.pad(fromMonth)}&to=${toYear}-${this.pad(
            toMonth
          )}`
        );
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.w-1\/7 {
  width: 14.285%;
}

.ml-1\/7 {
  margin-left: 14.285%;
}

.ml-2\/7 {
  margin-left: 28.57%;
}

.ml-3\/7 {
  margin-left: 42.85%;
}

.ml-4\/7 {
  margin-left: 57.14%;
}

.ml-5\/7 {
  margin-left: 71.42%;
}

.ml-6\/7 {
  margin-left: 85.71%;
}
</style>