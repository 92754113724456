<template>
  <PClickOutside :do="onClickOutside">
    <div class="relative">
      <PLabel
        :label="label"
        :error="error"
        :required="required"
        :disabled="disabled"
        @click="toggle"
      />
      <div class="relative">

        <div
          class="absolute bottom-0 left-0 right-0"
          v-if="loading"
        >
          <PLoader />
        </div>

        <button
          ref="toggle"
          :class="toggleButtonClassNames"
          :disabled="disabled"
          @click.prevent="toggle"
          @keydown.up.prevent.stop="up"
          @keydown.down.prevent.stop="down"
          @keydown.esc.prevent.stop="esc"
          @keydown.enter.prevent.stop="select"
          @keydown="keyDown"
          class="flex items-center justify-between w-full text-sm leading-none border xshadow-sm focus:outline-none"
        >

          <div
            class="flex-1 p-2 text-left"
            :class="{ 'text-gray-400': value == null }"
          >
            <slot
              name="caption"
              :item="selectedItem"
            >
              <div v-html="toggleButtonText"></div>
            </slot>
          </div>

          <div class="flex items-center">
            <div
              v-if="clearable && value"
              class="p-2"
              @click.prevent.stop="onClear"
            >
              <FontAwesomeIcon :icon="['far', 'times']" />
            </div>
            <div
              class="p-2 transition duration-100 ease-in-out transform"
              :class="{ '-rotate-180': isOpen }"
            >
              <FontAwesomeIcon :icon="['far', 'chevron-down']" />
            </div>
          </div>

        </button>
      </div>
      <div
        v-if="isOpen"
        ref="dropdown"
        :class="{ 'z-50': isOpen, 'max-h-72': height === 0 }"
        class="absolute flex flex-col w-full overflow-hidden origin-top-left bg-white border border-gray-500 rounded-b shadow-lg"
        :style="'margin-top:-1px;' + (height > 0 ? `height:${height}px` : '')"
      >
        <div
          v-if="searchable"
          class="relative flex items-center p-2 bg-gray-100 border-b border-gray-300 flex-0"
        >

          <FontAwesomeIcon
            class="absolute ml-2 text-gray-400"
            :icon="['far', 'search']"
          />

          <input
            ref="filter"
            v-model="filter"
            :placeholder="$tk('Common.General.SearchText', true)"
            class="w-full h-10 border border-gray-300 rounded shadow-sm p-y2 px-7 focus:outline-none"
            data-lpignore="true"
            @keydown.down.prevent.stop="down"
            @keydown.enter.prevent.stop="select"
            @keydown.esc.prevent.stop="esc"
            @keydown.up.prevent.stop="up"
            @keydown="keyDown"
          />

          <button
            v-if="filter !== ''"
            class="absolute p-2 text-gray-400 right-3 focus:text-gray-500 focus:outline-none"
            @click.prevent.stop="filter = ''"
          >
            <FontAwesomeIcon :icon="['far', 'times']" />
          </button>

        </div>
        <div class="flex-1 overflow-y-auto shadow-inner">

          <button
            v-for="(item, index) in filteredItems"
            :key="index"
            :ref="`option-${index}`"
            :class="{
              'bg-gray-200': index === focusIndex,
              'bg-green-500 text-white': value === item[itemValue]
            }"
            class="block w-full p-2 text-left border-b border-gray-200 focus:outline-none"
            @mouseover="focusIndex = index"
            @keydown.esc.prevent.stop="esc"
            @click.prevent="select"
          >
            <div class="text-sm">
              <slot :item="item">
                <span v-html="item[itemText]"></span>
              </slot>
            </div>
          </button>
        </div>

      </div>
      <PError v-if="error" :error="error" />
    </div>
  </PClickOutside>
</template>

<script>
import PError from "./partials/PError"
import PLabel from "./partials/PLabel"

import {
  find,
  filter,
  findIndex,
} from "lodash"

import { mapGetters } from "vuex"

export default {
  name: "p-select",
  components: {
    PError,
    PLabel
  },
  props: {
    value: { required: true },
    items: { type: Array, default: () => [] },
    itemText: { type: String, default: "text" },
    itemValue: { type: String, default: "value" },
    label: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: "" },
    error: { type: String, default: "" },
    searchable: { type: Boolean, default: false },
    searchFromCharCount: { type: Number, default: 1 },
    clearable: { type: Boolean, default: false },
    focus: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    fixedHeight: { type: Boolean, default: true },
    height: { type: Number, default: 0 },
    startOpen: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },


  data () {
    return {
      filter: "",
      focusIndex: -1,
      isOpen: false,
      wasOpened: false
    }
  },


  computed: {

    ...mapGetters(["customer", "location"]),

    filteredItems () {
      const filterText = this.filter.toLowerCase()
      const shouldFilter = filterText.length >= this.searchFromCharCount
      return shouldFilter ? filter(this.items, item => {
        return `${item[this.itemText]}${item[this.itemValue]}`
        .toLowerCase()
        .indexOf(filterText) !== -1
      }) : this.items
    },

    toggleButtonText () {
      const item = find(this.items, item => item[this.itemValue] === this.value)
      return item ? item[this.itemText] : this.placeholder
    },

    selectedItem () {
      return find(this.items, item => item[this.itemValue] === this.value)
    },

    toggleButtonClassNames () {
      return {
        "h-10": this.fixedHeight,
        "bg-white border-gray-400 focus:border-white focus:shadow-outline": !this.error && !this.disabled,
        "bg-gray-50 border-gray-300 text-gray-500 cursor-not-allowed": this.disabled,
        "border-red-500 focus:border-red-600 placeholder-red-300 text-red-600 bg-white ": this.error,
        "rounded": !this.isOpen,
        "rounded-t": this.isOpen,
        "border-gray-500": this.isOpen
      }
    }

  },


  methods: {

    onClear () {
      this.$emit("input", "")
      this.filter = ""
      this.isOpen = false
    },

    onClickOutside () {
      this.isOpen = false
    },

    async toggle () {

      this.isOpen = !this.isOpen

      if (this.isOpen) {

        if (this.searchable) {
          this.$nextTick(() => {
            this.$refs.filter.focus()
          })
        }

        if (this.value) {
          this.focusIndex = findIndex(this.filteredItems, item => item[this.itemValue] === this.value)
          if (this.focusIndex >= 0) {
            this.$nextTick(() => {
              this.$refs[`option-${this.focusIndex}`][0].scrollIntoView({
                block: "nearest",
                inline: "start"
              })
            })
          }

        } else {
          this.filter = ""
          this.focusIndex = 0
        }
      }

    },

    up () {
      if (this.focusIndex > 0) {
        this.focusIndex -= 1
        this.scrollToFocusIndex()
      }
    },

    down () {
      if (this.focusIndex < this.filteredItems.length - 1) {
        this.focusIndex += 1
        this.scrollToFocusIndex()
      }
    },

    esc () {
      if (this.isOpen) {
        this.isOpen = false
        this.$nextTick(() => {
          this.$refs.toggle.focus()
        })
      }
    },

    keyDown ($event) {
      const code = $event.keyCode
      const ctrl = $event.ctrlKey
      const pageSize = 6

      if (code === 33) { // page up
        $event.preventDefault()
        if (this.filteredItems.length > 0) {
          this.focusIndex = this.focusIndex - (this.focusIndex >= pageSize ? pageSize : this.focusIndex)
          this.scrollToFocusIndex()
        }
      } else if (code === 34) { // page down
        $event.preventDefault()
        if (this.filteredItems.length > 0) {
          this.focusIndex = ((this.focusIndex + pageSize) < this.filteredItems.length) ? this.focusIndex + pageSize : this.filteredItems.length - 1
          this.scrollToFocusIndex()
        }
      } else if (code === 35 && ctrl) { // end
        $event.preventDefault()
        if (this.filteredItems.length > 0) {
          this.focusIndex = this.filteredItems.length - 1
          this.scrollToFocusIndex()
        }
      } else if (code === 36 && ctrl) { // home
        $event.preventDefault()
        if (this.filteredItems.length > 0) {
          this.focusIndex = 0
          this.scrollToFocusIndex()
        }
      }
    },

    scrollToFocusIndex () {
      if (this.isOpen) {
        this.$refs[`option-${this.focusIndex}`][0].scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start"
        })
      }
    },

    select () {
      if (!this.isOpen) {
        this.toggle()
      } else {
        if (this.focusIndex !== -1) {
          this.$emit("input", this.filteredItems[this.focusIndex][this.itemValue])
          this.filter = ""
          this.isOpen = false

          this.$nextTick(() => {
            this.$refs.toggle.focus()
          })
        }
      }
    },
  },

  watch: {

    filter: function () {
      this.focusIndex = -1
    },

    // when hidden
    focusIndex: function (val) {
      if (!this.isOpen && val >= 0) {
        this.$emit("input", this.filteredItems[val][this.itemValue])
      }
    },

    items: function (val) {
      if (this.startOpen && !this.wasOpened && val.length > 0) {
        this.isOpen = true
        this.wasOpened = true
        this.$nextTick(() => {
          this.$refs.filter.focus()
        })
      }
    }
  },

  created () {
    if (this.focus) {
      this.$nextTick(() => {
        this.$refs.toggle.focus()
      })
    }
  }

}
</script>

<!--
<style scoped>

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-left: theme("colors.gray.300");
}

::-webkit-scrollbar-track {
  background-color: theme("colors.gray.100");
}

::-webkit-scrollbar-thumb {
  background-color: theme("colors.gray.300");
}

</style>
-->