<template>
  <div>
    <PLabel
      :label="label"
      :error="error"
      @click="$refs.input.focus()"
    />
    <div class="relative group">
      <FontAwesomeIcon
        v-if="warnActive"
        :icon="['far', 'exclamation-triangle']"
        class="absolute ml-2 text-sm text-yellow-500 top-1/2"
        v-tooltip="{ content: warnMessage, delay: { show: 100, hide: 100 } }"
      />
      <input
        ref="input"
        v-model="value"
        :class="classNames"
        :disabled="disabled"
        :max="max"
        :min="min"
        :step="step"
        :placeholder="placeholder"
        :readonly="readonly"
        type="number"
      />
    </div>
    <PError
      v-if="error"
      :error="error"
    />
  </div>
</template>

<script>
import PLabel from './partials/PLabel';
import PError from './partials/PError';

export default {
  name: 'p-number',
  components: {
    PLabel,
    PError
  },
  props: {
    value: { type: Number, default: 0 },
    label: { type: String, default: '' },
    max: { type: Number, default: Number.POSITIVE_INFINITY },
    min: { type: Number, default: Number.NEGATIVE_INFINITY },
    step: { type: Number, required: false },
    placeholder: { type: String, default: '' },
    size: {
      type: String,
      default: 'normal',
      validator: (value) => ['small', 'normal'].indexOf(value) !== -1
    },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    error: { type: String, default: '' },
    focus: { type: Boolean, default: false }
  },
  computed: {
    warnActive() {
      return (
        this.value > 0 &&
        ((this.warn > 0 && this.value >= this.warn) ||
          (this.warnBelow > 0 && this.value <= this.warnBelow))
      );
    },

    classNames() {
      const inputClasses = ['w-full leading-none border rounded shadow-inner focus:outline-none text-right', {
        'bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed': this.disabled,
        'border-gray-400 focus:border-white focus:shadow-outline':
          !this.error && !this.disabled && !this.readonly,
        'border-red-500 focus:border-red-600 placeholder-red-300 text-red-600': this.error,
        'p-2 h-10': this.size === 'normal',
        'p-1 h-8 text-xs': this.size === 'small',
        'text-gray-500': this.readonly,
      }]
      return inputClasses;
    },
  },
  mounted() {
    if (this.focus) {
      this.$refs.input.focus();
    }
  }
};
</script>
