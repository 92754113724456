<template>
  <div :class="['p-4 text-sm group', {
    'border-b border-dashed border-gray-300': border
  }]">
    <router-link
      :to="{ name: routeName, params: routeParams }"
      class="relative block pl-6"
    >
      <div class="absolute top-0 left-0">
        <FontAwesomeIcon :icon="['far', icon]" />
      </div>
      <div class="font-semibold text-green-500 underline group-hover:text-black">{{ alert.title }}</div>
      <p class="mt-1">{{ alert.text }}</p>
    </router-link>
    <!-- <template v-else>
      <div class="text-xs font-medium">{{ alert.updated | dateAndTime }}</div><br/>
      source: {{ alert.source }}<br/>
      reason: {{ alert.reason }}<br/>
      title: {{ alert.title }}<br/>
      text: {{ alert.text }}<br/>
      sourceId: {{ alert.sourceId }}
    </template> -->
  </div>
</template>

<script>
import { getAlertIcon, getAlertRouteName } from '@/utilities/alertHelpers';

export default {
  props: {
    alert: { type: Object, required: true },
    border: { type: Boolean, default: true },
    withLink: { type: Boolean, default: true }
  },
  computed: {
    icon() {
      return getAlertIcon(this.alert.source)
    },
    routeName() {
      return getAlertRouteName(this.alert.source)
    },
    routeParams() {
      const params = { id: this.alert.sourceId }

      if (this.alert.source === "Invoice") {
        params.type = "customer"
      }

      return params
    }
  }
}
</script>