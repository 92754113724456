import i18n from "@/utilities/i18n"

function install (Vue) {
  Vue.prototype.$tk = function (key, asPlainText = false) {

    const showKeys = (location.search || "").indexOf("translation-keys") !== -1

    return showKeys && asPlainText ? key :
           showKeys && !asPlainText ? `<span title="${key}">${i18n.t(key)}</span>` : i18n.t(key)

  }
}

export default install