<template>
  <!-- Tailwind UI -->

  <!--
    Notification panel, show/hide based on alert state.

    Entering: "transform ease-out duration-300 transition"
      From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      To: "translate-y-0 opacity-100 sm:translate-x-0"
    Leaving: "transition ease-in duration-100"
      From: "opacity-100"
      To: "opacity-0"
  -->
  <li class="w-full max-w-sm mb-4 bg-white rounded-lg shadow-lg pointer-events-auto">
    <div class="overflow-hidden rounded-lg shadow-xs">
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <svg
              v-if="notification.type === 'positive'"
              class="w-6 h-6 text-green-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <svg
              viewBox="0 0 20 20"
              v-if="notification.type === 'negative'"
              fill="currentColor"
              class="w-6 h-6 text-red-600 exclamation-circle"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-medium leading-5 text-gray-900">{{notification.title}}</p>
            <p class="mt-1 text-sm leading-5 text-gray-500">{{notification.text}}</p>
          </div>
          <div class="flex flex-shrink-0 ml-4">
            <button
              @click.prevent="$emit('close', notification)"
              class="inline-flex text-gray-400 transition duration-150 ease-in-out focus:outline-none focus:text-gray-500"
            >
              <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>
<script>
export default {
  name: "PNotification",
  props: {
    notification: {
      type: Object,
      required: true,
    },
    duration: {
      type: Number,
      required: true,
    },
  },
  created() {
    setTimeout(() => {
      this.$emit("close", this.notification);
    }, this.duration);
  },
};
</script>