<template>
  <div class="relative">

    <PClickOutside :do="() => isOpen = false">
      <button
        class="relative text-green-500 hover:text-black focus:outline-none focus:text-black"
        title="Varsler"
        @click="onToggle"
      >
        <div
          v-if="filteredAlerts.length > 0"
          class="badge"
        >{{ filteredAlerts.length }}</div>
        <font-awesome-icon
          :icon="['far', 'bell']"
          class="text-2xl"
        />
      </button>
    </PClickOutside>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <div
        v-if="isOpen"
        class="absolute right-0 z-20 mt-1 overflow-x-hidden overflow-y-auto origin-top-right bg-white border border-gray-300 rounded shadow-lg w-96 max-h-96"
      >
        <PAlertItem
          v-for="(alert, index) in filteredAlerts"
          :key="index"
          :alert="alert"
          :border="index < filteredAlerts.length - 1"
        />
      </div>
    </transition>

    <PConfirm
      v-if="mustReadAlerts.length > 0 && !whitelistRoute"
      :title="mustReadTitle"
    >
      <PAlertMustReadItem
        v-for="(alert, index) in mustReadAlerts"
        :key="`must-read-item--${index}`"
        :alert="alert"
      />
      <div slot="buttons"></div>
    </PConfirm>

  </div>
</template>

<script>

import {
  setIntervalAsync,
  clearIntervalAsync
} from "set-interval-async/dynamic"
import { mapGetters } from "vuex"
import { filter } from "lodash"

import PAlertItem from "./PAlertItem"
import PAlertMustReadItem from "./PAlertMustReadItem"

export default {
  components: { PAlertItem, PAlertMustReadItem },
  data() {
    return {
      isOpen: false,
      timer: null,
    }
  },
  computed: {
    ...mapGetters(["location", "alerts", "user", "loginUser"]),
    filteredAlerts() {
      return filter(this.alerts, a =>
        !(a.source === "Order") &&
        !(a.source === "Support") &&
        !(a.source === "Message" && a.data.read)
      )
    },
    mustReadAlerts() {
      const userIsLoginUser = this.user.id === this.loginUser.id
      return userIsLoginUser ? filter(this.filteredAlerts, a => !a.data.read && a.data.mustRead) : []
    },
    whitelistRoute() {
      return this.$route.name.indexOf("messages") === 0 ||
        this.$route.name.indexOf("api") === 0 ||
        this.$route.name.indexOf("global") === 0
    },
    mustReadTitle() {
      return this.mustReadAlerts.length > 1
        ? this.$tk("PAlertsDropdown.ImportantMessages")
        : this.$tk("PAlertsDropdown.ImportantMessage")
    }
  },
  watch: {
    events: async function() {
      await this.$store.dispatch("getAlerts")
    },
  },
  methods: {
    onToggle() {
      this.isOpen = !this.isOpen
    },
  },
  async created() {
    await this.$store.dispatch("getAlerts")

    this.timer = setIntervalAsync(async () => {
      await this.$store.dispatch("getAlerts")
    }, 30000)

  },
  async beforeDestroy() {
    if (this.timer) {
      await clearIntervalAsync(this.timer)
    }
  },
}
</script>