<template>
  <p-click-outside :do="clickOutside">
    <div class="relative w-52">
      <button
        ref="toggle"
        :disabled="disabled"
        :class="toggleClasses"
        @click.prevent="toggle"
        @keydown.up.prevent="up"
        @keydown.down.prevent="down"
        @keydown.esc="esc"
        @keydown.space="toggleStatus"
        class="flex items-center justify-between w-full h-10 text-sm leading-none border shadow-inner focus:border-gray-500 focus:outline-none"
        >
        <div class="p-2">
          <template v-if="selectedCount">
            {{ selectedCount }} av {{ totalCount }} status valgt
          </template>
          <template v-else>
            Velg status
          </template>
        </div>
        <div class="p-2 transition duration-100 ease-in-out transform" :class="{ '-rotate-180': isOpen }">
          <font-awesome-icon :icon="['far', 'chevron-down']" />
        </div>
      </button>
      <div v-if="isOpen" class="absolute z-10 flex flex-col w-full overflow-hidden bg-white border border-gray-400 rounded-b shadow" style="margin-top:-1px">

        <button
          @click="toggleAll"
          class="p-2 border-b border-gray-400 focus:outline-none"
          >
          <div class="flex items-center">
            <font-awesome-icon :icon="['far', allSelected ? 'check-square' : 'square']" />
            <div class="ml-2 text-sm">
              (Alle)
            </div>
          </div>
        </button>

        <button
            v-for="(status, index) in statuses"
            :key="index"
            :ref="`option-${index}`"
            :class="{ 'bg-green-500 text-white': status.selected || index === focusIndex }"
            class="flex items-center w-full p-2 text-left border-b border-gray-200 focus:outline-none"
            @mouseover="focusIndex = index"
            @click.prevent="toggleStatus"
          >
          <div class="flex items-center">
            <font-awesome-icon :icon="['far', status.selected ? 'check-square' : 'square']" />
            <div class="ml-2 text-sm">
              {{ status.displayName }}
            </div>
          </div>
        </button>

      </div>
    </div>

    <p-error v-if="error" :error="error" />
  </p-click-outside>
</template>

<script>
import PError from "./partials/PError";
import { forEach, filter, some, find, map, every } from "lodash";
import { mapGetters } from "vuex";

export default {
	name: "p-status-picker",
	components: { PError },
	props: {
		value: {
			type: String,
			default: "",
		},
		showStatuses: {
			type: String,
			default: "",
		},
		label: {
			type: String,
			default: "",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		error: {
			type: String,
			default: "",
		},
	},

	data() {
		return {
			isOpen: false,
			statuses: [],
			focusIndex: -1,
		};
	},

	computed: {
		...mapGetters(["transactionStatuses"]),

		toggleClasses() {
			return {
				"rounded-t": this.isOpen,
				rounded: !this.isOpen,
				"bg-white border-gray-400 focus:border-gray-500":
					!this.error && !this.disabled,
				"bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed":
					this.disabled,
				"border-red-500 focus:border-red-600 placeholder-red-300 text-red-600":
					this.error,
			};
		},

		allSelected() {
			return every(this.statuses, (status) => status.selected);
		},

		selectedIds() {
			const selected = filter(this.statuses, (status) => status.selected);
			const ids = map(selected, (selected) => selected.value);
			return ids.join(",");
		},

		totalCount() {
			return this.statuses.length;
		},

		selectedCount() {
			return this.selectedIds.length;
		},
	},

	methods: {
		toggle() {
			const wasOpen = this.isOpen;
			this.isOpen = !this.isOpen;
			this.focusIndex = 0;

			// user hit enter to close, need to emit selections
			if (wasOpen) {
				this.emitSelections();
			}
		},

		up() {
			if (this.focusIndex > 0) {
				this.focusIndex -= 1;
			}
		},

		down() {
			if (this.focusIndex < this.transactionStatuses.length - 1) {
				this.focusIndex += 1;
			}
		},

		toggleStatus($event) {
			$event.preventDefault();
			if (this.focusIndex < this.statuses.length - 1) {
				const status = this.statuses[this.focusIndex];
				status.selected = !status.selected;
			}
			this.$refs.toggle.focus();
		},

		toggleAll() {
			const allSelected = this.allSelected;
			forEach(this.statuses, (status) => {
				status.selected = allSelected ? false : true;
			});
		},

		clickOutside() {
			this.emitSelections();
			this.isOpen = false;
		},

		esc() {
			this.isOpen = false;
			this.emitSelections();
			this.$nextTick(() => {
				this.$refs.toggle.focus();
			});
		},

		emitSelections() {
			this.$emit("input", this.selectedIds);
		},
	},

	created() {
		const showStatusIds = map(this.showStatuses.split(","), (s) => parseInt(s));
		const filteredStatuses = this.showStatuses
			? filter(this.transactionStatuses, (status) =>
					some(showStatusIds, (id) => id === status.value),
				)
			: this.transactionStatuses;
		this.statuses = map(filteredStatuses, (status) => {
			const name = status.name;

			const displayName =
				name === "Web"
					? "Kladd"
					: name === "Ordered"
						? "Bestilt"
						: name === "Treatment"
							? "Under behandling"
							: name === "ConfirmedWaba"
								? "Bekreftet"
								: name === "Picked"
									? "Plukket"
									: name === "WaitForSort"
										? "Sorteres"
										: name === "WaitForAccept"
											? "Sortert"
											: name === "WaitForAgreement"
												? "Sortert m/mangler"
												: name === "ReceivedSort"
													? "Ankommet"
													: name === "Sent"
														? "Sendt"
														: name === "NotAgreed"
															? "Uenighet"
															: name === "WaitForConfirmation"
																? "Bekr. kunde"
																: name === "CancelledWaba"
																	? "Kansellert"
																	: name;

			return {
				...status,
				displayName,
				selected: false,
			};
		});

		if (this.value) {
			const ids = map(this.value.split(","), (s) => parseInt(s));
			forEach(ids, (id) => {
				const status = find(this.statuses, (status) => status.value === id);
				if (status) {
					status.selected = true;
				}
			});
		}
	},
};
</script>