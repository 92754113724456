<template>
  <button :class="classNames" :disabled="disabled || loading" :type="type" @click="onClick">
    <template v-if="loading">
      <font-awesome-icon :icon="['far', 'spinner']" pulse />
    </template>
    <template v-else>
      <FontAwesomeIcon v-if="icon" :icon="['far', icon]" />

      <template v-if="text">
        <div :class="{ 'ml-2': icon !== '' }" v-html="text"></div>
      </template>
      <template v-else>
        <div :class="{ 'ml-2': icon !== '' }"><slot /></div>
      </template>
    </template>
  </button>
</template>
<script>
export default {
  name: 'p-button',
  props: {
    classes: { Type: String, default: '' },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    text: { type: String, default: '' },
    icon: { type: String, default: '' },
    align: { type: String, default: 'center' },
    type: {
      type: String,
      default: 'button',
      validator: (value) => ['button', 'submit'].indexOf(value) !== -1
    },
    color: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'secondary', 'danger', 'white'].indexOf(value) !== -1
    }
  },
  methods: {
    onClick($event) {
      if (this.type !== 'submit') {
        $event.preventDefault();
        this.$emit('click');
      } else {
        this.$emit('submit', true);
      }
    }
  },
  computed: {
    classNames() {
      return [
        'inline-flex items-center h-10 px-5 py-2 text-sm font-semibold leading-none transition duration-200 border border-transparent rounded-full select-none',
        {
          'focus:outline-none': true,

          'cursor-not-allowed': this.disabled || this.loading,

          'bg-green-500 text-white hover:bg-green-400 focus:shadow-outline':
            this.color === 'primary' && !this.disabled && !this.loading,
          'bg-green-300 text-green-100':
            this.color === 'primary' && (this.disabled || this.loading),

          'bg-red-500 text-white hover:bg-red-400 focus:bg-red-400':
            this.color === 'danger' && !this.disabled && !this.loading,
          'bg-red-300 text-red-100': this.color === 'danger' && (this.disabled || this.loading),

          'bg-white border-gray-400 text-gray-700 hover:border-gray-500 focus:border-white focus:shadow-outline':
            this.color === 'secondary' && !this.disabled && !this.loading,
          'bg-white border-gray-300 text-gray-400':
            this.color === 'secondary' && (this.disabled || this.loading),

          'justify-center': this.align === 'center',
          'justify-start': this.align === 'left',
          'justify-end': this.align === 'right'
        }
      ];
    }
  }
};
</script>
